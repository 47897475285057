import { HorizontalDivider, StyledButton, StyledCollapseArrowContainer, StyledProfilePannelContainer, StyledSpinner } from "../../Styles";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { IoLogOutOutline, IoPersonCircleOutline } from "react-icons/io5";
import { IProfileIcon, useFullHeight, useProfilePannelIcons, useToggle } from "../../CustomHooks";
import React, { useContext, useRef } from "react";
import { Theme } from "../../Theme";
import ButtonAndLabel from "./ButtonAndLabel";
import { AuthContext } from "../../Context/AuthContext";
import { useLogout } from "../../CustomHooks/useLogout";
import { useNavigate } from "react-router-dom";
import { useCheckout } from "../../CustomHooks/useCheckout";
import { selectUserHasPaid } from "../../Selectors/AuthSelectors";

const overrideButtonStyles = {
  width: "32px",
  height: "32px",
  marginLeft: "24px",
  borderRadius: "10px",
  flexShrink: 0,
};

const profileIconStyle = { color: Theme.colors.white, width: 20, height: 20 };
const spinnerStyle = { size: "20px", color: Theme.colors.white };

export const ProfilePannel = React.memo(() => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const userHasPaid = selectUserHasPaid(state);
  const logout = useLogout();
  const profilePannelRef = useRef<any>();
  useFullHeight(profilePannelRef);
  const logoutLabelRef = useRef<any>();
  const nameLabelRef = useRef<any>();
  const checkoutLabelRef = useRef<any>();
  const { isToggledOn: isProfileMenuOpen, toggle: toggleIsProfileMenuOpen } = useToggle(true);
  const { profileIcons } = useProfilePannelIcons();
  const name = `${state.user?.firstName}`;
  const { createCheckoutSession, loading: checkoutLoading } = useCheckout();

  const handleNavigateToProfileSettings = () => {
    navigate("/profile");
  };

  return (
    <>
      <StyledProfilePannelContainer ref={profilePannelRef} isOpen={isProfileMenuOpen}>
        <StyledCollapseArrowContainer onClick={toggleIsProfileMenuOpen}>
          {!isProfileMenuOpen && <AiOutlineArrowRight style={{ width: 20, height: 20, right: 0 }} />}
          {isProfileMenuOpen && <AiOutlineArrowLeft style={{ width: 20, height: 20, right: 0 }} />}
        </StyledCollapseArrowContainer>
        <ButtonAndLabel
          componentRef={nameLabelRef}
          isOpen={isProfileMenuOpen}
          label={name}
          Component={React.useMemo(
            () => (
              <IoPersonCircleOutline
                size={40}
                style={{ color: Theme.colors.darkBlue, marginLeft: 20, flexShrink: 0 }}
                onClick={handleNavigateToProfileSettings}
              />
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
          )}
          overrideStyle={{ whiteSpace: "pre-wrap" }}
          onClick={handleNavigateToProfileSettings}
        />

        <HorizontalDivider />
        {profileIcons &&
          profileIcons
            .filter(({ hide }) => !hide)
            .map((item: IProfileIcon, index) => {
              return (
                <ButtonAndLabel
                  key={`${index}`}
                  componentRef={item.ref}
                  isOpen={isProfileMenuOpen}
                  label={item.label}
                  Component={
                    <StyledButton onClick={item.onClick} overrideStyles={overrideButtonStyles}>
                      {item.icon()}
                    </StyledButton>
                  }
                  onClick={item.onClick}
                />
              );
            })}
        {!userHasPaid && (
          <ButtonAndLabel
            componentRef={logoutLabelRef}
            isOpen={isProfileMenuOpen}
            label={
              <>
                Gain full
                <br />
                access
              </>
            }
            Component={
              <StyledButton overrideStyles={overrideButtonStyles} onClick={logout}>
                {checkoutLoading ? <StyledSpinner {...spinnerStyle} /> : <MdOutlineShoppingCartCheckout style={profileIconStyle} />}
              </StyledButton>
            }
            onClick={createCheckoutSession}
          />
        )}
        <ButtonAndLabel
          componentRef={checkoutLabelRef}
          isOpen={isProfileMenuOpen}
          label="Logout"
          Component={
            <StyledButton overrideStyles={overrideButtonStyles} onClick={logout}>
              {state.logoutLoading ? <StyledSpinner {...spinnerStyle} /> : <IoLogOutOutline style={profileIconStyle} />}
            </StyledButton>
          }
          onClick={logout}
        />
      </StyledProfilePannelContainer>
    </>
  );
});
