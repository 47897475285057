import { useContext } from "react";
import { TableContext } from "../Context/TableContext";
import { AuthContext } from "../Context/AuthContext";
import { shouldShowUploadGuestModal } from "../Utils";
import { selectNumberOfGuests } from "../Selectors";
import { selectUserHasPaid } from "../Selectors/AuthSelectors";

export const useShowUploadModal = () => {
  const { state: tableState } = useContext(TableContext);
  const { state: authState } = useContext(AuthContext);
  const numGuests = selectNumberOfGuests(tableState);
  const hasPaid = selectUserHasPaid(authState);
  return shouldShowUploadGuestModal({
    numGuests,
    hasPaid,
  });
};
