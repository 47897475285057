import { useCallback, useContext } from "react";
import { ModalContext } from "../Context/ModalContext";
import { useShowUploadModal } from "./useShowUploadModal";

export const useUploadModalToggled = () => {
  const { onUploadModalToggled, onShowPaywall } = useContext(ModalContext);
  const shouldShowUploadGuestModal = useShowUploadModal();
  return {
    onUploadModalToggled: useCallback(() => {
      if (shouldShowUploadGuestModal) {
        onUploadModalToggled();
      } else {
        onShowPaywall();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldShowUploadGuestModal]),
  };
};
