import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../Firebase";
import { stripePromise } from "../stripe";
import { logger } from "../Logger";

interface UseCheckoutReturn {
  createCheckoutSession: () => Promise<void>;
  loading: boolean;
  error: string | null;
}

export const useCheckout = (): UseCheckoutReturn => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createCheckoutSession = async () => {
    setLoading(true);
    setError(null);

    const createCheckout = httpsCallable(functions, "createCheckoutSession") as any;

    try {
      const {
        data: { id: sessionId },
      } = await createCheckout({});

      const stripe = await stripePromise;
      if (!stripe) {
        logger.log("Stripe failed to initialize");
        setError("Stripe failed to initialize");
        return;
      }
      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (stripeError) {
        logger.error(stripeError);
        setError("Failed to redirect to stripe. If the problem persists contact support.");
      }
      throw new Error("test");
    } catch (e) {
      logger.error(e);
      setError("Failed to redirect to stripe. If the problem persists contact support at support@simplifytables.com");
    } finally {
      setLoading(false);
    }
  };

  return {
    createCheckoutSession,
    loading,
    error,
  };
};
