import { useContext, useEffect } from "react";
import { TableContext } from "../../Context/TableContext";
import { StyledFormStepExplanation, StyledSpinner } from "../../Styles";
import { Theme } from "../../Theme";
import { logger } from "../../Logger";
import { AuthContext } from "../../Context/AuthContext";
import { selectUserHasPaid } from "../../Selectors/AuthSelectors";
import { GUEST_LIMIT } from "../../Utils";
import { CheckoutLink } from "../CheckoutLink";
import { useCheckout } from "../../CustomHooks/useCheckout";

interface IProps {
  handleFileUpload(): void;
}

export const CompleteUpload = ({ handleFileUpload }: IProps) => {
  const {
    state: { uploadGuestsError, reasonForRowsNotProcessedAfterUpload, uploadingGuests },
    clearUploadMessage,
  } = useContext(TableContext);
  const { state: authState } = useContext(AuthContext);
  const hasUserPaid = selectUserHasPaid(authState);
  useEffect(() => {
    logger.log("calling useEffect from CompleteUpload component");
    handleFileUpload();
    return () => {
      clearUploadMessage();
    };
  }, [clearUploadMessage, handleFileUpload]);
  const { createCheckoutSession, loading: loadingCheckout, error: checkoutError } = useCheckout();
  const showReasonsForNotProcessingSomeRows = reasonForRowsNotProcessedAfterUpload && reasonForRowsNotProcessedAfterUpload?.length;
  const showUploadSuccessful = !uploadGuestsError && !uploadingGuests && !showReasonsForNotProcessingSomeRows;
  const showCheckoutLink = !hasUserPaid && !uploadGuestsError && !uploadingGuests;

  if (checkoutError) {
    return <StyledFormStepExplanation color={Theme.colors.maroon}>{checkoutError}</StyledFormStepExplanation>;
  }
  if (loadingCheckout) {
    return (
      <div style={{ margin: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <StyledFormStepExplanation>Directing to checkout</StyledFormStepExplanation>
        <StyledSpinner size="40px" color={Theme.colors.darkBlue}></StyledSpinner>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
      {showCheckoutLink && (
        <StyledFormStepExplanation color={Theme.colors.mediumBlue} fontSize="18px">
          {"Your free acount only has access to"} <span style={{ fontSize: 20, fontWeight: "bold", color: "black" }}>{GUEST_LIMIT}</span>
          {` guest uploads`}
          <br /> To Unlock unlimited uploads{" "}
          <span>
            <CheckoutLink text="click here" createCheckoutSession={createCheckoutSession} loading={loadingCheckout} disabled={uploadingGuests} />
          </span>
        </StyledFormStepExplanation>
      )}

      {loadingCheckout && (
        <div style={{ margin: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <StyledFormStepExplanation>Directing to checkout</StyledFormStepExplanation>
          <StyledSpinner size="40px" color={Theme.colors.darkBlue}></StyledSpinner>
        </div>
      )}
      {uploadGuestsError && <StyledFormStepExplanation color={Theme.colors.maroon}>{uploadGuestsError}</StyledFormStepExplanation>}
      {showUploadSuccessful && <StyledFormStepExplanation color={Theme.colors.darkBlue}>Upload successful!</StyledFormStepExplanation>}
      {uploadingGuests && <StyledFormStepExplanation color={Theme.colors.darkBlue}>Processing...</StyledFormStepExplanation>}
      {showReasonsForNotProcessingSomeRows && (
        <>
          <StyledFormStepExplanation>Please note that some rows were not uploaded because:</StyledFormStepExplanation>
          <ul style={{ listStyle: "circle" }}>
            {reasonForRowsNotProcessedAfterUpload.map(({ row, reason }, index) => {
              const message = `${reason} - e.g. row ${row}`;
              return (
                <li>
                  <StyledFormStepExplanation marginBottom="5px">{message}</StyledFormStepExplanation>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};
