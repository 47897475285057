import { useCallback, useContext } from "react";
import { ModalContext } from "../Context/ModalContext";
import { EventContext } from "../Context/EventContext";

export const useEventNameModalToggled = () => {
  const { onEditEventNameModalOpened } = useContext(ModalContext);
  const { state } = useContext(EventContext);
  return {
    onEventNameModalToggled: useCallback(() => {
      if (state.event?.name === "My Event") {
        onEditEventNameModalOpened();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.event?.name]),
  };
};
