import { createContext, useReducer, useCallback } from "react";
import {
  deleteGuest as deleteGuestFromDB,
  addGuestsToSidePannel,
  createTables,
  ICreateTableData,
  moveGuestsInBatch,
  getAllTablesForEvent,
  updateTable as updateTableInDB,
  updateGuest as updateGuestInDB,
  deleteTable as deleteTableInDB,
} from "../Firebase/firestore";
import { processCSVInput, sanitizeString } from "../Utils";
import { v4 as uuidv4 } from "uuid";
import {
  tableReducer,
  ADD_TABLE_REQUEST,
  ITableReducerState,
  DRAG_END,
  TOnDragEnd,
  TSidePannelSearched,
  SIDE_PANNEL_SEARCHED,
  TOP_PANNEL_SEARCHED,
  TTopPannelSearched,
  TTableCollapsed,
  TABLE_COLLAPSED,
  TTable,
  FETCH_TABLES_PENDING,
  FETCH_TABLES_FAILURE,
  FETCH_TABLES_SUCCESS,
  TGuestRemovedFromTable,
  GUEST_REMOVED_FROM_TABLE,
  GuestFormat,
  GUESTS_UPLOAD_SUCCESS,
  GUESTS_UPLOAD_FAILURE,
  GUESTS_UPLOAD_REQUEST,
  DELETE_GUEST_REQUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAILURE,
  TableInput,
  ADD_TABLE_SUCCESS,
  ADD_TABLE_FAILURE,
  MOVE_GUESTS_LOADING,
  MOVE_GUESTS_FAILURE,
  TGuestPath,
  MOVE_GUESTS_SUCCESS,
  TTableMap,
  TABLE_MODAL_OPENED,
  UPDATE_TABLE_REQUEST,
  UPDATE_TABLE_SUCCESS,
  UPDATE_TABLE_FAILURE,
  GUEST_MODAL_OPENED,
  UPDATE_GUEST_SUCCESS,
  UPDATE_GUEST_REQUEST,
  UPDATE_GUEST_FAILURE,
  NEXT_SEARCH_RESULT,
  PREVIOUS_SEARCH_RESULT,
  TGuest,
  guestFormatInputKeys,
  CLEAR_UPLOAD_MESSAGE,
  DELETE_TABLE_REQUEST,
  DELETE_TABLE_FAILURE,
  DELETE_TABLE_SUCCESS,
  RESET_STATE,
} from "./tableReducer";
import { logger } from "../Logger";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getTestTables = (): TTableMap => {
  const tables: { [key: string]: TTable } = {};
  //eslint-disable-next-line array-callback-return
  Array.from({ length: 50 }, (_, i) => {
    tables[`table${i + 1}`] = {
      tableNumber: i + 1,
      capacity: 20,
      tableId: `table${i + 1}`,
      guests: Array.from({ length: 12 }, (_, j) => {
        return {
          firstName: alphabet.charAt(Math.floor(Math.random() * alphabet.length)) + j,
          lastName: alphabet.charAt(Math.floor(Math.random() * alphabet.length)) + "udmayer" + j,
          id: j + `table${i}`,
          format: GuestFormat.Format2,
        };
      }),
    };
  });
  return tables;
};

interface IFetchTables {
  eventId: string;
}
export interface IDeleteGuest {
  tableId: string;
  tableIdToDeleteFrom: string;
  guestId: string;
}

export interface IUpdateTableData {
  tableName?: string;
  capacity?: number;
  tableNumber?: number;
  tableId: string;
}

interface IGuestBaseData {
  guestId: string;
  newGuestId: string;
  tableIdToUpdateInDB: string;
  tableId: string;
  eventId: string;
}

export interface IGuestFormat1Data extends IGuestBaseData {
  format: GuestFormat.Format1;
  title: string;
  lastName: string;
  initial: string;
}

export type IUpdateGuestDataInDB = IGuestFormat1Data | IGuestFormat2Data;
export type IUpdateGuestData =
  | {
      format: GuestFormat.Format2;
      firstName: string;
      lastName: string;
    }
  | {
      format: GuestFormat.Format1;
      title: string;
      lastName: string;
      initial: string;
    };

export interface IGuestFormat2Data extends IGuestBaseData {
  format: GuestFormat.Format2;
  firstName: string;
  lastName: string;
}

export interface IGuestModalOpened {
  tableId: string;
  guestId: string;
}

interface ITableContext {
  state: ITableReducerState;
  addTables: (tableInput: TableInput[], eventId: string) => Promise<void>;
  fetchTables: (args: IFetchTables) => void;
  onDragEnd: (payload: TOnDragEnd) => void;
  onSidePannelSearched: (payload: TSidePannelSearched) => void;
  onTopPannelSearched: (payload: TTopPannelSearched) => void;
  onTableCollapsed: (payload: TTableCollapsed) => void;
  removeGuestFromTable: (payload: TGuestRemovedFromTable) => void;
  deleteGuest: (args: IDeleteGuest, eventId: string) => void;
  uploadGuests: (eventId: string, payload: any, format: GuestFormat | undefined, hasPaid: boolean) => void;
  moveGuests: (eventId: string, guestPaths: TGuestPath) => void;
  uploadGuestRequest: () => void;
  onTableModalOpened(tableId: string): void;
  onGuestModalOpened(args: IGuestModalOpened): void;
  updateTable(args: IUpdateTableData, eventId: string): void;
  deleteTable(eventId: string): Promise<void>;
  updateGuest(args: IUpdateGuestDataInDB): Promise<void>;
  nextSearchResultClicked(): void;
  previousSearchResultClicked(): void;
  uploadGuestFailure(e: string): void;
  clearUploadMessage(): void;
  resetState(): void;
}

export const TableContext = createContext<ITableContext>({
  state: {
    tables: { "": { capacity: NaN, tableNumber: NaN, guests: [], tableId: "" } },
    sidePannelSearchText: "",
    topPannelSearchText: "",
    collapsedTables: [],
    tableToScrollIntoView: undefined,
    uploadGuestsError: "",
    guestsNeedSaving: false,
    uploadingGuests: false,
    deletingGuest: false,
    addingTable: false,
    guestPaths: {},
    moveGuestsLoading: false,
    moveGuestsError: null,
    tableOpenInModel: "",
    guestOpenInModal: "",
    tableOfguestOpenInModal: "",
    updatingTable: false,
    updatingGuest: false,
    deletingTable: false,
    fetchTablesLoading: true,
    searchInfo: [],
  },
  addTables: async () => {},
  onDragEnd: () => {},
  onSidePannelSearched: () => {},
  onTopPannelSearched: () => {},
  onTableCollapsed: () => {},
  fetchTables: () => {},
  removeGuestFromTable: () => {},
  uploadGuests: () => {},
  uploadGuestRequest: () => {},
  deleteGuest: () => {},
  moveGuests: () => {},
  onTableModalOpened() {},
  onGuestModalOpened() {},
  updateTable() {},
  deleteTable: async () => {},
  updateGuest: async () => {},
  nextSearchResultClicked() {},
  previousSearchResultClicked() {},
  uploadGuestFailure() {},
  clearUploadMessage() {},
  resetState() {},
});
export const initialState: ITableReducerState = {
  tables: {
    sidePannel: {
      tableNumber: -1,
      capacity: NaN,
      guests: [],
      tableId: "sidePannel",
    },
    // ...getTestTables(),
  },
  sidePannelSearchText: "",
  topPannelSearchText: "",
  collapsedTables: [],
  tableToScrollIntoView: undefined,
  uploadGuestsError: undefined,
  guestsNeedSaving: false,
  uploadingGuests: false,
  deletingGuest: false,
  addingTable: false,
  deletingTable: false,
  moveGuestsLoading: false,
  moveGuestsError: null,
  guestPaths: {},
  updatingTable: false,
  updatingGuest: false,
  tableOpenInModel: "",
  guestOpenInModal: "",
  tableOfguestOpenInModal: "",
  fetchTablesLoading: true,
  searchInfo: [],
};

export const TableProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);

  const addTables = async (tableInput: TableInput[], eventId: string) => {
    try {
      dispatch({ type: ADD_TABLE_REQUEST });
      const createTableData: ICreateTableData[] = tableInput.map((input) => {
        return {
          ...input,
          tableId: uuidv4(),
        };
      });
      await createTables(createTableData, eventId);
      dispatch({ type: ADD_TABLE_SUCCESS, payload: createTableData });
    } catch (e) {
      dispatch({ type: ADD_TABLE_FAILURE });
    }
  };

  const onDragEnd = (payload: TOnDragEnd) => {
    dispatch({ type: DRAG_END, dropResult: payload.dropResult });
  };

  const onSidePannelSearched = (payload: TSidePannelSearched) => {
    dispatch({ type: SIDE_PANNEL_SEARCHED, searchText: payload.searchText });
  };

  const onTopPannelSearched = (payload: TTopPannelSearched) => {
    dispatch({ type: TOP_PANNEL_SEARCHED, searchText: payload.searchText });
  };

  const onTableCollapsed = (payload: TTableCollapsed) => {
    dispatch({ type: TABLE_COLLAPSED, ...payload });
  };

  const uploadGuestRequest = useCallback(() => {
    dispatch({ type: GUESTS_UPLOAD_REQUEST });
  }, []);

  const clearUploadMessage = useCallback(() => {
    dispatch({ type: CLEAR_UPLOAD_MESSAGE });
  }, []);

  const uploadGuestFailure = useCallback((error: string) => {
    dispatch({ type: GUESTS_UPLOAD_FAILURE, error });
  }, []);

  const deleteGuest = async (args: IDeleteGuest, eventId: string) => {
    dispatch({ type: DELETE_GUEST_REQUEST });
    try {
      await deleteGuestFromDB(args, eventId);
      dispatch({ type: DELETE_GUEST_SUCCESS, payload: args });
    } catch (e) {
      logger.error(e);
      dispatch({ type: DELETE_GUEST_FAILURE });
    }
  };

  const uploadGuests = async (eventId: string, payload: { data: any; fields: any }, format: GuestFormat | undefined, hasPaid: boolean) => {
    try {
      if (!format) {
        throw new Error("Format not defined");
      }
      const columns = payload.fields;
      if (!csvHasCorrectColumns(columns, format)) {
        uploadGuestFailure(
          "The file you've uploaded contains inaccurate column headers. Please ensure that the column headers are spelled precisely as they are represented in the image on the previous step"
        );
        return;
      }
      const exisitingGuests = getAllGuests(state.tables);
      const { guests, rowsNotProcessed } = processCSVInput(payload.data, format, exisitingGuests, hasPaid);
      await addGuestsToSidePannel(eventId, guests);
      dispatch({ type: GUESTS_UPLOAD_SUCCESS, guests, rowsNotProcessed });
    } catch (e) {
      logger.error(e);
      uploadGuestFailure("There was an error processing your file");
    }
  };

  const moveGuests = async (eventId: string, guestPaths: TGuestPath) => {
    try {
      dispatch({ type: MOVE_GUESTS_LOADING });
      await moveGuestsInBatch(eventId, guestPaths);
      dispatch({ type: MOVE_GUESTS_SUCCESS });
    } catch (e) {
      logger.error(e);
      dispatch({ type: MOVE_GUESTS_FAILURE });
    }
  };

  const fetchTables = useCallback(async (args: IFetchTables) => {
    async function fetchWithRetry(eventId: string, maxAttempts = 3, attemptDelay = 500) {
      let lastError;
      for (let attempt = 1; attempt <= maxAttempts; attempt++) {
        try {
          return await getAllTablesForEvent(eventId);
        } catch (error) {
          lastError = error;
          logger.warn(`Attempt ${attempt} failed: ${error}`);
          if (attempt < maxAttempts) {
            await new Promise((resolve) => setTimeout(resolve, attemptDelay * attempt));
          }
        }
      }
      throw lastError;
    }
    try {
      logger.warn("FETCHING");
      const { eventId } = args;
      dispatch({ type: FETCH_TABLES_PENDING });
      const tables = await fetchWithRetry(eventId);
      dispatch({ type: FETCH_TABLES_SUCCESS, payload: { tables } });
    } catch (e) {
      const error = e instanceof Error ? e.message : "An Error occurred while fetching the guest list. Please reload the page.";
      logger.error(error);
      dispatch({ type: FETCH_TABLES_FAILURE, error });
    }
  }, []);

  const updateTable = useCallback(async (args: IUpdateTableData, eventId: string) => {
    try {
      dispatch({ type: UPDATE_TABLE_REQUEST });
      await updateTableInDB(args, eventId);
      dispatch({ type: UPDATE_TABLE_SUCCESS, payload: { ...args } });
    } catch (e) {
      logger.error(e);
      dispatch({ type: UPDATE_TABLE_FAILURE });
    }
  }, []);

  const deleteTable = async (eventId: string) => {
    try {
      dispatch({ type: DELETE_TABLE_REQUEST });
      const { tableOpenInModel, guestPaths, tables } = state;
      const guestIdsInCurrentTable = tables[tableOpenInModel].guests.map(({ id }) => id);
      const guestPathsIds = Object.keys(guestPaths);
      const guestPathsToUpdate = guestIdsInCurrentTable.reduce((acc: TGuestPath, cur: string) => {
        if (guestPathsIds.includes(cur)) {
          return { ...acc, [cur]: [...guestPaths[cur], "sidePannel"] };
        }
        return { ...acc, [cur]: [tableOpenInModel, "sidePannel"] };
      }, {});
      const guestPathsToSave = { ...guestPaths, ...guestPathsToUpdate };
      await moveGuestsInBatch(eventId, guestPathsToSave);
      await deleteTableInDB(eventId, tableOpenInModel);
      dispatch({ type: DELETE_TABLE_SUCCESS });
    } catch (e) {
      dispatch({ type: DELETE_TABLE_FAILURE });
    }
  };

  const updateGuest = async (args: IUpdateGuestDataInDB) => {
    try {
      dispatch({ type: UPDATE_GUEST_REQUEST });
      await updateGuestInDB(args);
      dispatch({ type: UPDATE_GUEST_SUCCESS, payload: { ...args } });
    } catch (e) {
      logger.error(e);
      dispatch({ type: UPDATE_GUEST_FAILURE });
    }
  };

  const onTableModalOpened = (tableId: string) => {
    dispatch({
      type: TABLE_MODAL_OPENED,
      tableId,
    });
  };

  const onGuestModalOpened = (args: IGuestModalOpened) => {
    dispatch({
      type: GUEST_MODAL_OPENED,
      ...args,
    });
  };

  const removeGuestFromTable = (payload: TGuestRemovedFromTable) => {
    const { tableId, guestId } = payload;
    dispatch({ type: GUEST_REMOVED_FROM_TABLE, tableId, guestId });
  };

  const nextSearchResultClicked = () => {
    dispatch({ type: NEXT_SEARCH_RESULT });
  };

  const previousSearchResultClicked = () => {
    dispatch({ type: PREVIOUS_SEARCH_RESULT });
  };

  const resetState = () => {
    dispatch({ type: RESET_STATE });
  };

  return (
    <TableContext.Provider
      value={{
        state,
        addTables,
        onDragEnd,
        onSidePannelSearched,
        onTopPannelSearched,
        onTableCollapsed,
        fetchTables,
        removeGuestFromTable,
        uploadGuests,
        uploadGuestRequest,
        deleteGuest,
        moveGuests,
        onTableModalOpened,
        updateTable,
        deleteTable,
        onGuestModalOpened,
        updateGuest,
        nextSearchResultClicked,
        previousSearchResultClicked,
        uploadGuestFailure,
        clearUploadMessage,
        resetState,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const csvHasCorrectColumns = (columns: string[], format: GuestFormat) => {
  const formats = guestFormatInputKeys[format];
  const formatLength = formats.length;
  const columnLength = columns.length;
  if (formatLength !== columnLength) {
    return false;
  }
  const formatSorted = guestFormatInputKeys[format];
  const columsSorted = columns
    .slice()
    .map((column) => {
      return sanitizeString(column);
    })
    .sort();
  for (let i = 0; i < formatSorted.length; i++) {
    if (formatSorted[i] !== columsSorted[i]) {
      return false;
    }
  }
  return true;
};

const getAllGuests = (tableMap: TTableMap) => {
  return Object.keys(tableMap).reduce((acc: TGuest[], cur) => {
    if (tableMap[cur].guests) {
      acc = acc.concat(tableMap[cur].guests);
    }
    return acc;
  }, []);
};

export const guestExists = (tableMap: TTableMap, newGuestId: string) => {
  const existingGuests = getAllGuests(tableMap);
  const guestExists = !!existingGuests.find((guest) => {
    return guest.id === newGuestId;
  });
  return guestExists;
};
