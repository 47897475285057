import React from "react";
import styled from "styled-components";
import { Theme } from "../Theme";

interface CheckoutLinkProps {
  text: string;
  className?: string;
  disabled?: boolean;
  underline?: boolean;
  color?: string;
  hoverColor?: string;
  loading: boolean;
  createCheckoutSession(): Promise<void>;
}

const StyledLink = styled.a<{
  $disabled: boolean;
  $underline: boolean;
  $color: string;
  $hoverColor: string;
}>`
  color: ${(props) => props.$color};
  text-decoration: ${(props) => (props.$underline ? "underline" : "none")};
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.$disabled ? 0.6 : 1)};
  transition: color 0.2s ease-in-out;
  display: inline-block;
  position: relative;

  &:hover {
    color: ${(props) => !props.$disabled && props.$hoverColor};
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: ${(props) => props.$hoverColor};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.2s ease-in-out;
  }

  &:hover::after {
    transform: ${(props) => !props.$disabled && "scaleX(1)"};
    transform-origin: left;
  }
`;

export const CheckoutLink: React.FC<CheckoutLinkProps> = ({
  text,
  disabled = false,
  underline = true,
  color = Theme.colors.darkBlue,
  hoverColor = Theme.colors.mediumBlue,
  createCheckoutSession,
  loading,
}) => {
  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!disabled && !loading) {
      await createCheckoutSession();
    }
  };

  return (
    <div style={{ display: "inline-block" }}>
      <StyledLink onClick={handleClick} $disabled={disabled || loading} $underline={underline} $color={color} $hoverColor={hoverColor}>
        {text}
      </StyledLink>
    </div>
  );
};
